.Contact {
  background-color: var(---base);
}
.Contact .contactpage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}
.Contact h1 {
  margin: 0px 0 0px 10px;
  text-align: left;
  font-size: 120px;
  font-weight: 900;
  color: var(---baselight);
  font-family: "Montserrat", sans-serif;
  letter-spacing: -6px;
}

.Contact a {
  color: rgb(0, 0, 0);
}
.Contact a:hover {
  background-color: var(---baselight);
  color: var(---base);
}
/* contact */
.Contact .contactbox {
  width: max-content;
  padding: 5px 50px 10px 10px;
  border: 8px double var(---baselight);
  text-align: left;
  list-style: none;
  line-height: 60px;
  font-size: 24px;
  font-weight: 100;
  line-height: 3;
}

.Contact .highlight {
  font-weight: 100;
  color: var(---baselight) !important;
  letter-spacing: 2px;
}
@media (max-width: 992px) {
  .Contact h1 {
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .Contact h1 {
    margin-bottom: 30px;
    font-size: 90px;
    letter-spacing: -4px;
  }
  .Contact .contactbox {
    font-size: 24px;
    line-height: 55px;
  }
}
@media (max-width: 576px) {
  .Contact h1 {
    font-size: 73px;
    letter-spacing: -3px;
  }
  .Contact .contactbox {
    padding: 20px 80px 20px 30px;
    line-height: 40px;
  }
}
@media (max-width: 480px) {
  .Contact h1 {
    font-size: 63px;
    letter-spacing: -2px;
  }
  .Contact .contactbox {
    padding: 10px 10px 15px 20px;
    font-size: 22px;
    line-height: 38px;
  }
}
@media (max-width: 380px) {
  .Contact h1 {
    font-size: 55px;
  }
  .Contact .contactbox {
    padding: 5px 7px 10px 10px;
    font-size: 19px;
    line-height: 38px;
  }
}
