.Footer {
  background-color: var(---baselight);
  color: var(---base);
}
.Footer .footerlink {
  color: var(---basedark);
}
.Footer .footerlink:hover {
  background-color: var(---base);
  color: var(---baselight);
}
@media (max-width: 768px) {
  .Footer {
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  .Footer {
    font-size: 10px;
  }
}
