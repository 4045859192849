.Error {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}
.Error h1 {
  margin-top: 30px;
  font-size: 120px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: var(---basedark);
  letter-spacing: -7px;
}
.Error a {
  color: var(---baselight);
  transition: all 0.4s ease;
}
.Error a:hover {
  color: var(---basedark);
}
