.Navbar nav {
  position: sticky;
  top: 0;
  height: fit-content;
  padding: 2px 0;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.237);
  background-image: linear-gradient(
    to bottom,
    #fffcec,
    #fffcecf1,
    #fffcece5,
    #fffcecd8,
    #fffcecb5
  );
  z-index: 10;
}
.Navbar nav a {
  padding: 5px;
  color: var(---basedark);
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}
.Navbar nav a:hover {
  color: var(---baselight);
  text-decoration: none;
}
.Navbar nav a.active {
  color: var(---baselight);
  letter-spacing: 1px;
}
.Navbar nav img {
  height: 50px;
  margin: -5px 0 0 5px;
}

.Navbar .navbutton {
  display: inline-block;
  margin-left: 5px;
  padding: 4px 4px;
  border: 2px solid var(---basedark);
  background-color: #ffffff37;
  transition: all 0.4s ease;
}

.Navbar .navbutton:hover {
  background-color: #155b9570;
  color: var(---base);
}

.Navbar .navbar-toggler-icon {
  color: var(---basedark);
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

@media (max-width: 510px) {
  .Navbar nav img {
    height: 40px;
  }
}
