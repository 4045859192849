.Popup {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.popup-inner {
  position: relative;
  padding: 5px;
  max-width: 80%;
  width: 100%;
  border: 12px double var(---baselight);
  background-color: var(---base);
}
.popup-inner .close-btn {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  margin: 10px;
  padding: 3px 10px;
  box-shadow: 2px 1px 5px 1px #752b2b25;
  background-color: var(---baselight);
  font-size: 12px;
  color: var(---base);
}
.popup-inner .close-btn:hover {
  font-weight: 900;
}

@media (max-width: 1024px) {
  .Popup {
    top: 45px;
  }
}
@media (max-width: 470px) {
 
  .popup-inner {
    padding: 0px;
    max-width: 90%;
    border: 10px double var(---baselight);
  }
  .popup-inner .close-btn {
    font-size: 10px;
  }
}
