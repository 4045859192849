.Aboutme {
  background-size: 100px 100px, 20px 20px;
  background-image: conic-gradient(
      at calc(100% - 1px) calc(100% - 1px),
      #106bb720 270deg,
      rgba(0, 0, 0, 0) 0
    ),
    conic-gradient(
      at calc(100% - 1px) calc(100% - 1px),
      #106cb790 270deg,
      rgba(255, 0, 0, 0) 0
    );
}
.Aboutme .aboutmepage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: -140px;
  text-align: center;
}
.Aboutme h1 {
  margin: 140px 0 0 10px;
  text-align: left;
  font-size: 120px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: var(---basedark);
  letter-spacing: -7px;
}
.Aboutme .aboutInfo {
  margin: 30px 100px 50px 100px;
  box-shadow: 2px 1px 5px 1px #4e59612f;
  background-color: var(---base);
  text-align: left;
  font-size: 16px;
  line-height: 1.7;
}
.Aboutme img {
  max-width: 360px;
  filter: sepia(0.2);
}
.Aboutme figcaption {
  max-width: 360px;
  background-color: var(---basedark);
  text-wrap: wrap;
  font-size: 10px;
  font-weight: 100;
  color: var(---base);
  letter-spacing: 1.5px;
}
.Aboutme button {
  display: inline-block;
  box-shadow: 2px 1px 5px 1px #4e59612f;
  font-size: 16px;
}
.Aboutme button:hover {
  background-color: var(---basedark);
  color: var(---base);
}
.Aboutme .aboutImage {
  float: right;
  margin: -30px -35px 20px 5px;
  box-shadow: 2px 2px 5px 1px #4e59612f;
  font-size: 14px;
}

/*  */
@media (max-width: 1023px) {
  .Aboutme h1 {
    font-size: 110px;
  }
  .Aboutme img,
  .Aboutme figcaption {
    max-width: 270px;
  }

  .Aboutme .aboutInfo {
    margin: 30px 80px 50px 80px;
  }
}
@media (max-width: 960px) {
  .Aboutme h1 {
    margin-bottom: 20px;
  }
  .Aboutme .aboutInfo {
    margin: 15px 70px 50px 70px;
  }
}
@media (max-width: 768px) {
  .Aboutme h1 {
    font-size: 90px;
    letter-spacing: -6px;
  }
  .Aboutme img,
  .Aboutme figcaption {
    max-width: 180px;
  }
  .Aboutme button {
    font-size: 14px;
  }
  .Aboutme .aboutInfo {
    margin: 5px 70px 40px 70px;
  }
  .Aboutme figcaption {
    font-size: 9px;
    letter-spacing: 1px;
  }
}
@media (max-width: 576px) {
  .Aboutme h1 {
    font-size: 65px;
    letter-spacing: -3px;
  }
  .Aboutme img,
  .Aboutme figcaption {
    max-width: 140px;
  }
  .Aboutme button {
    font-size: 13px;
  }
  .Aboutme .aboutInfo {
    margin: 5px 50px 30px 50px;
  }
}
@media (max-width: 480px) {
  .Aboutme .aboutInfo {
    margin: 5px 20px 30px 20px;
    font-size: 14px;
  }
  .Aboutme .aboutImage {
    float: right;
    margin: -15px -17px 10px 2px;
  }
  .Aboutme h1 {
    font-size: 60px;
    letter-spacing: -2px;
  }
  .Aboutme button {
    font-size: 11px;
  }
}
@media (max-width: 380px) {
  .Aboutme h1 {
    font-size: 55px;
  }
}
