.Mailsection h2 {
  font-size: 30px;
  font-weight: 100;
  color: var(---baselight);
  letter-spacing: 3px;
}
.Mailsection button {
  width: fit-content;
  box-shadow: 2px 1px 5px 1px #ee6c6c2f;
  font-size: 20px;
}
.Mailsection button:hover {
  background-color: var(---baselight);
  color: var(---base);
}

.Mailsection form input,
.Mailsection form textarea {
  border: 2px solid var(---baselight);
  background-color: var(---base);
}

.Mailsection form input:focus,
.Mailsection form textarea:focus {
  border: 2px solid var(---basedark);
  background-color: var(---base);
}

.Mailsection form input::placeholder,
.Mailsection form textarea::placeholder {
  opacity: 0.8;
}
.Mailsection form .form-group {
  align-items: start;
  max-width: 90%;
}

@media (max-width: 992px) {
  .Mailsection {
    max-width: 65%;
  }
  .Mailsection form .form-group {
    max-width: 100%;
  }
}
@media (max-width: 740px) {
  .Mailsection {
    max-width: 80%;
  }
}
@media (max-width: 600px) {
  .Mailsection {
    max-width: 95%;
  }
  .Mailsection button {
    width: fit-content;
    box-shadow: 2px 1px 5px 1px #ee6c6c2f;
    font-size: 18px;
  }
}
