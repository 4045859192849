.Contactsection .contactsectionbox {
  border: 8px solid var(---baselight);
}
.Contactsection h4 {
  font-size: 80px;
  font-weight: 900;
  color: var(---baselight);
}
.Contactsection button {
  display: inline-block;
  width: fit-content;
  margin-bottom: 15px;
  padding: 3px 10px;
  box-shadow: 2px 1px 5px 1px #752b2b25;
  font-size: 20px;
}
.Contactsection button:hover {
  background-color: var(---baselight);
  color: var(---base);
}
@media (max-width: 576px) {
  .Contactsection h4 {
    font-size: 60px;
  }
  .Contactsection button {
    font-size: 16px;
  }
}
@media (max-width: 420px) {
  .Contactsection h4 {
    font-size: 44px;
  }
  .Contactsection button {
    font-size: 12px;
  }
}
