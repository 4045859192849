.Projects {
  background-color: var(---basedark);
}
.Projects .projectspage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: -140px;
}
.Projects h1 {
  margin: 140px 0 35px 10px;
  font-size: 120px;
  font-weight: 900;
  color: var(---base);
  font-family: "Montserrat", sans-serif;
  letter-spacing: -7px;
}
.Projects .popuphighlight {
  font-weight: 900;
  color: var(---baselight);
  text-transform: uppercase;
}
.Projects .cardText {
  margin: 0;
  font-size: 19px;
  font-weight: 900;
  color: var(---basedark);
}
.Projects .card {
  display: inline-block;
  max-width: 320px;
  margin-bottom: 40px;
  padding: 15px 15px 5px 15px;
  outline: 5px solid var(---basedark);
  outline-offset: -10px;
  background-color: var(---base);
}
.Projects .cardicon {
  font-size: 22px;
  color: var(---basedark);
}
.Projects .visitButton,
.popupButton {
  display: block;
  width: fit-content;
  margin: 20px auto 5px;
  padding: 3px 10px;
  box-shadow: 1px 1px 5px 1px #1b2e472f;
  font-size: 14px;
}
.Projects .visitButton:hover,
.popupButton:hover {
  background-color: var(---basedark);
  color: var(---base);
}
/*  Popups  */
.Popup h2 {
  margin-right: 80px;
  font-size: 40px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: var(---baselight);
  letter-spacing: -2px;
}
.Popup .xlimage {
  max-height: 410px;
}
.Popup .launchbutton {
  display: block;
  width: fit-content;
  margin: 20px auto 5px;
  padding: 3px 10px;
  box-shadow: 2px 1px 5px 1px #752b2b25;
  font-size: 14px;
}
.Popup .launchbutton:hover {
  background-color: var(---baselight);
  color: var(---base);
}
.Popup .popupcontent {
  padding: 10px;
}

@media (max-width: 1023px) {
  .Projects h1 {
    font-size: 110px;
  }
  .Popup .popupText {
    font-size: 15px;
  }
  .Popup h2 {
    font-size: 35px;
  }
  .Popup .popupcontent {
    padding: 20px;
  }
}

@media (max-width: 960px) {
  .Projects h1 {
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .Projects h1 {
    font-size: 90px;
    letter-spacing: -6px;
  }
  .Projects .cardicon {
    font-size: 16px;
  }

  /*  */

  .Popup h2 {
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .Projects h1 {
    font-size: 65px;
    letter-spacing: -3px;
  }
  .Projects .visitButton,
  .popupButton {
    font-size: 12px;
  }
  .Popup .popupcontent {
    padding: 0 15px 0 18px;
  }
}

@media (max-width: 480px) {
  .Projects h1 {
    font-size: 60px;
    letter-spacing: -2px;
  }
}
@media (max-width: 400px) {
  .Projects h1 {
    font-size: 55px;
    letter-spacing: -2px;
  }
  .Popup h2 {
    font-size: 30px;
  }
  .Popup .popupText {
    font-size: 12px;
  }

  .Popup .launchbutton {
    font-size: 12px;
  }
}
