.Projectsection {
  padding-bottom: 40px;
  background-color: var(---basedark);
  color: var(---base);
}

.Projectsection h4 {
  font-size: 80px;
  font-weight: 900;
}
.Projectsection img {
  max-width: 60%;
}

.Projectsection a {
  display: inline-block;
   box-shadow: 2px 1px 4px 3px #001a323b;
  font-size: 18px;
  text-decoration: none;
}

.Projectsection a:hover {
  background-color: var(---base);
  border: 1px solid var(---basedark);
  color: var(---basedark);
}
.Projectsection .projectmargin {
  align-items: center;
  margin-top: -10px;
}
.Projectsection .projectIntro {
  font-size: 26px;
}
.Projectsection .projectTools {
  opacity: 0.7;
  font-size: 18px;
}
.Projectsection .projectImageright {
  float: right;
  margin-right: 50px;
}
.Projectsection .projectImageleft {
  float: left;
  margin-left: 50px;
}
.Projectsection .projectTextleft,
.projectTextright {
  font-size: 18px;
}
.Projectsection .projectTextleft {
  padding-left: 140px;
}
.Projectsection .projectTextright {
  padding-right: 140px;
}
.Projectsection .projectIntro button {
  padding: 0 12px;
  border: none;
  background-color: #00000000;
  color: var(---baselight);
  transition: all 0.4s ease;
}

.Projectsection .projectIntro button:hover {
  border: none;
  background-color: var(---base);
  color: var(---basedark);
}
@media (max-width: 1023px) {
  .Projectsection .projectTextleft,
  .projectTextright {
    font-size: 17px;
  }
  .Projectsection .projectmargin {
    margin-top: -4px;
  }
}
@media (max-width: 960px) {
  .Projectsection .projectdescription {
    font-size: 13px;
  }
  .Projectsection .projectmargin {
    margin-top: -2px;
  }
  .Projectsection .projectTextleft {
    padding-left: 100px;
  }
  .Projectsection .projectTextright {
    padding-right: 80px;
  }
  .Projectsection .projectTools {
    font-size: 13px;
  }
  .Projectsection a {
    font-size: 11px;
  }
}

@media (max-width: 800px) {
  .Projectsection a {
    font-size: 10px;
  }
}
@media (max-width: 720px) {
  .Projectsection .projectTextright {
    padding-right: 35px;
  }
  .Projectsection .projectTextleft {
    padding-left: 35px;
  }
  .Projectsection img {
    max-width: 80%;
  }
  .Projectsection .projectImageright {
    margin-right: 10px;
  }
  .Projectsection .projectImageleft {
    margin-left: 10px;
  }
}
@media (max-width: 600px) {
  .Projectsection .projectIntro {
    font-size: 20px;
  }
  .Projectsection .projectmargin {
    margin-top: 10px;
  }
}
@media (max-width: 576px) {
  .Projectsection .projectImageright {
    margin-right: 10px;
    max-height: 350px;
  }
  .Projectsection .projectImageleft {
    margin-left: 10px;
  }
  .Projectsection .projectIntro {
    font-size: 17px;
  }
  .Projectsection .projectdescription {
    font-size: 14px;
    padding: 0 10px;
  }
}
@media (max-width: 420px) {
  .Projectsection h4 {
    font-size: 55px;
  }
  .Projectsection .projectIntro {
    font-size: 14px;
  }
}
