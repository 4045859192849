.Aboutsection .aboutbox {
  padding: 0 100px;
}
.Aboutsection .profilepicture {
  height: 450px;
  filter: drop-shadow(-30px -11px 1px var(---baselight));
  z-index: -10;
}
.Aboutsection .abouttext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Aboutsection .abouttext p {
  padding: 40px 74px 0px 160px;
  font-size: 28px;
}

.Aboutsection .abouttext button {
  display: inline-block;
  margin: 0 auto;
  border: none;
  background-color: #00000000;
  color: var(---baselight);
  transition: all 0.4s ease;
}

.Aboutsection .abouttext button:hover {
  border: none;
  background-color: var(---baselight);
  color: var(---base);
}
/* code CODE code */
.Aboutsection .codeimage {
  margin: 80px 0 0 10px;
  padding: 80px 0 0 25px;
  height: 352px;
  width: 558px;
  background-image: url("../../media/codelight.png");
  font-size: 24px;
  font-weight: 800;
  line-height: 38px;
  letter-spacing: 1.5px;
}
.Aboutsection .codeimagetwo {
  margin: -53px 0 0 246px;
  padding: 40px 0 0 20px;
  height: 274px;
  width: 468px;
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 1.4px;
  background-image: url("../../media/codelightsmall.png");
}
.Aboutsection .codeimage,
.Aboutsection .codeimagetwo {
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Patrick Hand SC", cursive;
  z-index: 1;
}
.Aboutsection .colorone {
  color: var(---basedark);
}
.Aboutsection .colortwo {
  color: var(---baselight);
}
.Aboutsection .colorthree {
  color: var(---secondary);
}
@media (max-width: 1200px) {
  .Aboutsection .codeimage {
    margin: 35px 0 0 2px;
  }

  .Aboutsection .abouttext p {
    padding: 50px 140px 0px 160px;
    font-size: 24px;
  }
  .Aboutsection .profilepicture {
    height: 400px;
  }
  .Aboutsection .aboutbox {
    padding: 0 70px;
  }
}

@media (max-width: 1023px) {
  .Aboutsection .aboutbox {
    padding: 0 50px;
  }
}
@media (max-width: 960px) {
  .Aboutsection .profilepicture {
    margin-left: -10px;
  }
  .Aboutsection .aboutbox {
    padding: 0;
  }
  .Aboutsection .abouttext p {
    padding: 38px 80px 0 155px;
  }
}
@media (max-width: 800px) {
  .Aboutsection .abouttext p {
    padding: 9px 33px 0 172px;
  }

  .Aboutsection .codeimage {
    margin: 51px 0 0 14px;
  }
}

@media (max-width: 720px) {
  .Aboutsection .aboutbox {
    padding: 0;
  }
  .Aboutsection .codeimagetwo {
    margin: -49px 0 0 96px;
  }

  .Aboutsection .abouttext p {
    font-size: 20px;
    padding: 0 41px 0 91px;
  }

  .Aboutsection .profilepicture {
    height: 280px;
    filter: drop-shadow(-11px -6px 1px var(---baselight));
  }
}
@media (max-width: 600px) {
  .Aboutsection .abouttext p {
    padding: 0px 5px 0 123px;
  }

  .Aboutsection .codeimagetwo {
    margin: -49px 0 0 96px;
    height: 226px;
    width: 388px;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 1.1px;
  }
  .Aboutsection .profilepicture {
    margin-left: -20px;
  }
}
@media (max-width: 540px) {
  .Aboutsection .profilepicture {
    height: 200px;
  }
  .Aboutsection .abouttext p {
    padding: 0 13px 0 60px;
    font-size: 15px;
  }
  .Aboutsection .codeimagetwo {
    margin: -25px 0 0 113px;
    padding: 30px 0 0 16px;
    height: 175px;
    width: 293px;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 1px;
  }
}
@media (max-width: 420px) {
  .Aboutsection .aboutbox {
    margin-top: 20px;
  }
  .Aboutsection .profilepicture {
    height: 150px;
    margin-left: -24px;
    filter: drop-shadow(-9px -4px 1px var(---baselight));
  }

  .Aboutsection .codeimagetwo {
    margin: -22px 0 0 34px;
    padding: 26px 0 0 10px;
    height: 155px;
    width: 270px;
    font-size: 11px;
    line-height: 19px;
    letter-spacing: 0.9px;
  }
  .Aboutsection .abouttext p {
    padding: 0 8px 0 68px;
    font-size: 12px;
  }
}
