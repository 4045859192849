.Home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}
.Home .homepage {
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.Home .hero {
  min-height: 600px;
  margin-top: -100px;
  padding: 170px 0 100px 0;
  background-image: conic-gradient(
      at calc(100% - 1px) calc(100% - 1px),
      #106bb720 270deg,
      rgba(0, 0, 0, 0) 0
    ),
    conic-gradient(
      at calc(100% - 1px) calc(100% - 1px),
      #106cb790 270deg,
      rgba(255, 0, 0, 0) 0
    );
  background-size: 100px 100px, 20px 20px;
}
.Home .hero h1 {
  font-size: 120px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: var(---basedark);
  letter-spacing: -5px;
}
.Home .hero h2 {
  font-size: 50px;
}
.Home .hero h3 {
  font-size: 40px;
}
.Home .hero button {
  display: inline-block;
  box-shadow: 2px 1px 5px 1px #4e59612f;
  font-size: 20px;
}
.Home .hero button:hover {
  border: none;
  background-color: var(---baselight);
  color: var(---base);
}
@media (max-width: 1023px) {
  .Home .hero {
    padding-top: 150px;
  }
  .Home .hero h1 {
    font-size: 110px;
    letter-spacing: -5px;
  }
  .Home .hero h2 {
    font-size: 46px;
  }
  .Home .hero h3 {
    font-size: 36px;
  }
}
@media (max-width: 960px) {
  .Home .hero h1 {
    font-size: 105px;
    letter-spacing: -5px;
  }
}
@media (max-width: 720px) {
  .Home .hero h1 {
    font-size: 90px;
    letter-spacing: -5px;
  }
  .Home .hero h2 {
    font-size: 36px;
  }
  .Home .hero h3 {
    font-size: 26px;
  }
  .Home .hero button {
    letter-spacing: 1px;
    font-size: 18px;
  }
}
@media (max-width: 600px) {
  .Home .hero {
    padding: 140px 0 70px 0;
  }
}
@media (max-width: 540px) {
  .Home .hero h1 {
    font-size: 70px;
    letter-spacing: -2px;
  }
  .Home .hero h2 {
    font-size: 26px;
  }
  .Home .hero h3 {
    font-size: 20px;
  }
  .Home .hero button {
    font-size: 16px;
  }
  .Home .hero {
    min-height: 400px;
    padding: 120px 0 40px 0;
  }
}
@media (max-width: 420px) {
  .Home .hero h1 {
    font-size: 50px;
  }
  .Home .hero h2 {
    font-size: 22px;
  }
  .Home .hero h3 {
    font-size: 18px;
  }
  .Home .hero button {
    font-size: 13px;
  }
  .Home .hero {
    min-height: 400px;
    padding: 130px 0 60px 0;
  }
}
