:root {
  ---base: #fffcec;
  ---baselight: #ee6c6c;
  ---basedark: #155b95;
  ---secondary: #87b6dd;
}
.App {
  max-width: 100%;
  background-color: #fffcec;
}
/* generic className */
.btn-base-dark {
  border: 1px solid var(---basedark);
  background-color: var(---base);
  color: var(---basedark);
}
.btn-dark-base {
  border: 1px solid var(---base);
  background-color: var(---basedark);
  color: var(---base);
}

.btn-base-light {
  border: 1px solid var(---baselight);
  background-color: var(---base);
  color: var(---baselight);
}
.btn-base-dark,
.btn-dark-base,
.btn-base-light {
  border-radius: 10px;
  font-weight: 100;
  letter-spacing: 3px;
  text-transform: uppercase;
  transition: all 0.4s ease;
}
